import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";

import "./404.scss";

const NotFoundPage = () => (
  <div className="page-not-found white-header-bg">
    <Layout>
      <div className="error-msg">
        <h1 className="error-heading">Whoops...</h1>
        <p className="error-text">Page not found!</p>
      </div>
    </Layout>
  </div>
);

export const Head = ({ location }) => {
  const seoMeta = {
    metaTitle: "404: Not found",
    locale: "en-US",
    slug: location.pathname,
  };
  return <Seo {...seoMeta} />;
};

export default NotFoundPage;
